<template>
  <div class="card">
    <div class="card-block adaptive-table">
      <paginated-table-filter
        v-if="isShowFilters"
        class="mb-4 "
        :formdata="filterFormdata"
        :is-search="isSearch"
        :is-csv="!!csvHandler"
        :is-large-list="isLargeList"
        @download-csv="csvDownloadHandler"
        @update="getMessagesList(1)"
        @clear-date="clearDate"
      ></paginated-table-filter>
      <slot :list="messageList" :license="license" :is-loading="isLoading"></slot>
      <app-pagination
        v-if="page && messageList.length > 0"
        :page="page"
        :page-count="pageCount"
        :per-page="perPage"
        @update-page="getMessagesList($event)"
      ></app-pagination>
      <p v-if="isLoadData && messageList.length === 0" class="text-center">{{ $t('noResults') }}</p>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import isEmpty from 'ramda/src/isEmpty'

export default {
  name: 'PaginatedTableWrapper',
  components: {
    'paginated-table-filter': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Filter.vue')),
    'app-pagination': defineAsyncComponent(() => import('@/application/components/layout/AppPagination.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
    isShowFilters: {
      type: Boolean,
      default: true,
    },
    request: {
      required: true,
    },
    isSearch: {
      type: Boolean,
      default: true,
    },
    defaultSearch: {
      type: String,
      required: false,
    },
    sort: {
      type: String,
      default: 'desc',
    },
    csvHandler: {
      type: Function,
    },
    isLargeList: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const messageList = ref([])
    const page = ref(null)
    const pageCount = ref(null)
    const perPage = ref(null)
    const isLoadData = ref(false)
    const isLoading = ref(false)
    const filterFormdata = ref({
      sort: props.sort,
      search: props.defaultSearch || '',
      per_page: 20,
    })
    const csvDownloadHandler = () => props.csvHandler(messageList.value)
    const clearDate = () => {
      delete filterFormdata.value.date
    }
    const getMessagesList = (selectedPage) => {
      const parameters = JSON.parse(JSON.stringify(filterFormdata.value))
      Object.keys(parameters).forEach(key => {
        if (isEmpty(parameters[key])) {
          delete parameters[key]
        }
      })
      if (parameters.date) {
        const startDate = new Date(parameters.date.start)
        const endDate = new Date(parameters.date.end)
        parameters.from_date = startDate.toISOString().split('T')[0]
        parameters.to_date = endDate.toISOString().split('T')[0]
        delete parameters.date
      }
      parameters.page = selectedPage
      isLoading.value = true
      props.request(parameters, {
        Authorization: props.license.license_key,
        'Loop-Secret-Key': props.license.secret_api_key,
      })
        .then(({ data }) => {
          isLoadData.value = true
          isLoading.value = false
          page.value = data.page
          pageCount.value = data.num_pages
          perPage.value = data.per_page
          messageList.value = data.items || data.subscriptions || data.platforms
        })
    }
    onMounted(() => {
      getMessagesList(1)
    })
    return {
      getMessagesList,
      page,
      pageCount,
      perPage,
      messageList,
      isLoadData,
      isLoading,
      filterFormdata,
      clearDate,
      csvDownloadHandler,
    }
  },
}
</script>
